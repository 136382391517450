<template>
  <div>
    <v-container fluid>
      <v-row v-if="accesscontrol && accesscontrol.edit">
        <v-col cols="12" class="pa-0 px-4 pb-0">
          <v-select :items="formChecklists" :disabled="!hasPermission" @change="goToChecklistForm" item-text="name" item-value="_id" v-model="getChecklist" filled
          :label="$t('checklists')" dense hide-details full-width></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <compact-list :payload="compactListPayload" :total="total" ref="checklistCompactList"></compact-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import helpers from '@/utils/actions'
export default {
  props: ['module', 'filterObj', 'model', 'payload', 'accesscontrol'],
  data () {
    return {
      getChecklist: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.PROPS_INDEX) ? this.$store.state.common.PROPS_INDEX : { itemsPerPage: 8, page: 1 },
      search: '',
      formChecklists: [],
      moduleName: 'checklists',
      /* pwa */
      compactListPayload: {
        showBackBtn: () => this.$parent.$parent.removeActiveModule(),
        hideHeader: false,
        editHandler: () => this.updateChecklistDialog(),
        refreshHandler: this.getListHandler,
        downloadHandler: (item) => this.downloadChecklist(item.checklist_id, item.form_id, item.name),
        moduleTitle: 'Checklists',
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: 'PROPS_INDEX',
        items: [{ value: 'name' }, { value: 'modified_at' }],
        hasFilter: false,
        isCustomModule: false, // flag to show the relate unrelate options
        isChecklist: true,
        moduleIcon: 'mdi-book-plus',
        hasPermission: true,
        accesscontrol: this.accesscontrol
      },
      deleteSuccessPagination: null,
      moduleObj: {
        moduleId: this.$route.params.id,
        moduleName: this.$route.params.name,
        recordId: this.$route.params.record_id
      },
      hasPermission: false,
      total: 0
    }
  },
  components: {
    'compact-list': () => import('@/components/CompactList')
  },
  computed: {
    ...mapGetters(['getChecklistForms', 'listOfModules', 'userDetails']),
    headers () {
      return [{
        text: this.$t('name'),
        value: 'name'
      }, {
        text: `${this.$t('updatedOn')}`,
        value: 'modified_at'
      }, {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false
      }]
    }
  },
  created () {
    this.getListOfChecklists()
  },
  watch: {
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
    }
  },
  mounted () {
    this.setPermission()
    this.$root.$on('deleteItems', (data) => {
      if (data.module === this.moduleName) {
        this.deleteSuccessPagination = data.pagination
        const payload = { ids: [data.id], url: `${this.moduleName}/delete_multiple`, module: this.moduleName }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.moduleName) {
        this.pagination = this.deleteSuccessPagination
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
        this.$store.commit('hideDialog')
        this.getListHandler()
      }
    })
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
    this.getListHandler()
  },
  methods: {
    downloadChecklist (checklistId, formId, formName) {
      this.$eventBus.$emit('showLoading')
      helpers.downloadReportHelper(checklistId, formId, formName, process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN)
        .then(() => {
          this.$eventBus.$emit('hideLoading')
        })
    },
    getListOfChecklists () {
      const query = [
        { $match: { $and: [{ Module: { $eq: this.module } }, { Status: { $eq: 'completed' } }] } }
      ]
      this.$api.execute('post', 'checklisttemplates/query', query).then((response) => {
        this.formChecklists = response.data
      })
    },
    getListHandler (paginationObj) {
      this.compactListPayload.loading = true
      this.compactListPayload.module = this.moduleName
      const pagination = paginationObj && paginationObj.itemsPerPage ? paginationObj : this.pagination
      this.compactListPayload.pagination.page = pagination.page
      this.pagination.page = pagination.page
      const queries = [
        { $match: { $and: [{ Module: { $eq: this.module } }, { Module_Id: { $eq: this.$route.params.record_id } }] } }
      ]
      this.$api.execute('post', 'checklists/query', queries).then((response) => {
        this.compactListPayload.list = []
        const data = response.data
        this.total = response.data.length
        data.forEach(element => {
          element.modified_at = this.$formatter.formatDate(element.modified_at, 'DD.MM.YYYYTHH:mm:ss', `${this.userDetails.dateformat} HH:mm`)
        })
        this.compactListPayload.list = data
      }).finally(() => {
        this.compactListPayload.loading = false
      })
    },
    goToChecklistForm () {
      const getForm = this.formChecklists.find(x => x._id === this.getChecklist)
      if (getForm) {
        // this.$eventBus.$emit('checklistDialog', { form_id: getForm.form_id, form_name: getForm.name })
        this.$root.$emit('checklistDialog', { form_id: getForm.form_id, form_name: getForm.name })
      }
      return ''
    },
    updateChecklistDialog (item) {
      this.$eventBus.$emit('updatechecklistDialog', { checklist_id: item.checklist_id, form_id: item.form_id })
    },
    setPermission () {
      this.hasPermission = true
      this.compactListPayload.editHandler = this.updateChecklistDialog // hasManagePermission ? this.updateChecklistDialog : () => {}
    },
    clearChecklist () {
      this.getChecklist = null
      this.getListHandler()
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$root.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
