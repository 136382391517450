/* eslint-disable camelcase */
/* eslint-disable */
const generate_pdf_content = (form_name, fields, model, status_list, logoBase64) => {
  let styleLink = `<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet" />
    <style>
      tbody tr, .panel-body { page-break-inside:avoid; font-size: 18px}
      tbody tr td { text-align: justify;word-break: break-word;}
      .switch {position: relative;display: inline-block;width: 50px;height: 25px;}
      .switch input {opacity: 0;width: 0;height: 0;}
      .slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;-webkit-transition: .4s;transition: .4s;}
      .slider:before {position: absolute;content: "";height: 19px;width: 19px;left: 2px;bottom: 3px;background-color: white;-webkit-transition: .4s;transition: .4s;}
      input:checked + .slider {background-color: #1976d2;}
      input:focus + .slider {box-shadow: 0 0 1px #1976d2;}
      input:checked + .slider:before {-webkit-transform: translateX(26px);-ms-transform: translateX(26px);transform: translateX(26px);}
      .slider.round {border-radius: 34px;}
      .slider.round:before {border-radius: 50%;}
      .textWrap { word-break: break-all }
      .custom-margin-left {margin-left: 0px !important; margin-right: 10px !important;}
      .signature_13 {
        border: 1px solid rgba(0, 0, 0, 0.8);
        border-radius: 3px;
        padding: 0.5rem;
      }
      .badge {
        border-radius: 3px;
        width: fit-content !important;
      }
      table.table-padding-adjusted > thead > tr > th,
      table.table-padding-adjusted > tbody > tr > td {
          padding: 0.25rem !important;
      }
      .custom-margin { margin-bottom: 0.15rem; }
      .space-left-right {margin-right: 1.25rem;}
      .sign-date{ margin: 0 0.5rem;}
    </style>`
  let getHTMLContent = `<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width" />
    <meta charset="utf-8"/>${styleLink}</head>
    <body><br>
    <div class="container">
      <table class="table table-bordered">
        <tbody>
          <tr height="80px">
            <td align="center" style="border: none !important;vertical-align: middle !important;" width="100%">
              <div style="width: 85%;float: left;margin: 1rem 0;">
                <font size="4">
                  <strong>
                  FORM NAME: ${form_name}
                  </strong>
                </font>
              </div>
              <div style="width: 15%;float: right;display: none;flex-direction: row-reverse;">
                <img src="${logoBase64}" alt="" class="img-responsive" width="100%">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="panel panel-default">
        <div class="panel-body">
          ${pdf_data(fields, model, status_list)}
        </div>
      </div>
    </div>
    </body></html>`
  return btoa(unescape(encodeURIComponent(getHTMLContent)))
}

const pdf_data = (fields, model, status_list) => {
  let htmlData = ''
  let evenCount = 0
  for (let i = 0; i < fields.length; i++) {
    fields[i].default_value = (fields[i].default_value && typeof fields[i].default_value === 'string') ? JSON.parse(fields[i].default_value) : fields[i].default_value
    /* if no value then don't show undefined */
    fields[i].label = fields[i].label || ''
    /*  */
    if (i % 2 === 0) htmlData += `<div class="row">`
    evenCount++
    fields[i].default_value.options = fields[i].default_value.options || []
    switch (fields[i].type) {
      case 3: { // CHECKBOXES
        // let values = fields[i].default_value.options
        let viewType = fields[i].default_value.viewType || 'default'
        if (viewType === 'horizontal') viewType = 'default'
        /* View type Horizontal  & Vertical */
        let cb = `<input type="checkbox" />`
        let cbChecked = `<input type="checkbox" checked />`
        htmlData += '<div class="col-xs-6"><p class="custom-margin">'
        if (model && model[fields[i].name]) htmlData += `${cbChecked} `
        else htmlData += `${cb} `
        if (fields[i].label) htmlData += `<label>${fields[i].label}</label>`
        htmlData += `</p>`
        /* switch (viewType) {
          case 'vertical':
            for (let j = 0; j < values.length; j++) {
              htmlData += `<p class="custom-margin">`
              if (model && model[fields[i].name] && model[fields[i].name].find(x => x === values[j].value)) htmlData += `${cbChecked} ${values[j].label}`
              else htmlData += `${cb} ${values[j].label}`
              htmlData += `</p>`
            }
            break
          case 'default':
            htmlData += `<p class="custom-margin">`
            for (let j = 0; j < values.length; j++) {
              htmlData += `<span class="space-left-right">`
              values[j].label = values[j].label || ''
              if (model && model[fields[i].name] && model[fields[i].name].find(x => x === values[j].value)) htmlData += `${cbChecked} ${values[j].label}`
              else htmlData += `${cb} ${values[j].label}`
              htmlData += `</span>`
            }
            htmlData += `</p>`
            break
        } */
        htmlData += `</div>`
        break
      }
      case 4: { // RADIO BUTTONS
        let values = fields[i].default_value.options
        htmlData += `<div class="col-xs-6"><label>${fields[i].label}</label><br>`
        for (let value of values) {
          htmlData += ` <label class="radio-inline">`
          if (model && value.value === model[fields[i].name]) htmlData += `<input type="radio" name="${value.name}" checked> ${value.label}`
          else htmlData += `<input type="radio" name="${value.name}"> ${value.label}`
          htmlData += `</label>`
        }
        htmlData += `</div>`
        break
      }
      // case 5: { // SELECTS LIST
      //   let options = fields[i].default_value.options
      //   let isMultiSelect = fields[i].default_value.is_multiselect
      //   let select = model[fields[i].name] || []
      //   htmlData += `<div class="col-xs-6"><label>${fields[i].label}</label>`
      //   if (isMultiSelect) {
      //     let multiItems = []
      //     for (let item of select) {
      //       let getItem = options.find(x => x.value === item)
      //       let getText = getItem ? getItem.label : ''
      //       multiItems.push(getText)
      //     }
      //     htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${multiItems.join(', ')}</span>`
      //   } else if (fields[i].default_value.isAPI) {
      //     htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${model[fields[i].name] || ''} </span>`
      //   } else {
      //     if (options) {
      //       let getItem = options.find(x => x.value === select)
      //       let getText = getItem ? getItem.label : ''
      //       htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${getText || ''}</span>`
      //     }
      //   }
      //   htmlData += `</div>`
      //   break
      // }
      case 5: { // SELECTS LIST
        let options = fields[i].default_value.options
        let isMultiSelect = fields[i].default_value.is_multiselect
        let select = model[fields[i].name] || []
        htmlData += `<div class="col-xs-6"><label>${fields[i].label}</label>`
        if (isMultiSelect && !fields[i].default_value.isAPI) {
          let multiItems = []
          for (let item of select) {
            let getItem = options.find(x => x.value === item)
            let getText = getItem ? getItem.label : ''
            multiItems.push(getText)
          }
          htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${multiItems.join(', ')}</span>`
        } else if (isMultiSelect && fields[i].default_value.isAPI) {
          htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${model[fields[i].name] ? model[fields[i].name].join(', ') : '' || ''} </span>`
        } if (!isMultiSelect && fields[i].default_value.isAPI) {
          htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${model[fields[i].name] ? model[fields[i].name] : '' || ''} </span>`
        } else {
          if (options) {
            let getItem = options.find(x => x.value === select)
            let getText = getItem ? getItem.label : ''
            htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${getText || ''}</span>`
          }
        }
        htmlData += `</div>`
        break
      }
      case 8: { // SWITCH
        let checked = ''
        if (model && model[fields[i].name]) checked = `<input type="checkbox" checked />`
        else checked = `<input type="checkbox" />`
        htmlData += `<div class="col-xs-6">
          <label>${fields[i].label}</label>
          <label class="switch">
            ${checked}
            <span class="slider round"></span>
          </label></div>`
        break
      }
      case 9:
        htmlData += `<div class="col-xs-6"><label>${fields[i].label}</label> <span style="white-space: pre-wrap" class="textWrap">Files are attached last...</span></div>`
        break
      case 10: { // SURVEY COMPONENT
        let value = model[fields[i].name] ? model[fields[i].name]['answer'] : ''
        let options = fields[i].default_value.options || []
        let getItem = options.find(x => x.value === value)
        let getText = getItem ? getItem.label : ''
        let isHideComments = fields[i].default_value.hideComments
        htmlData += `<div class="col-xs-12">
            <table class="table table-bordered table-padding-adjusted" style="width: 100%">
              <tbody>
                <tr>
                  <th width="${isHideComments ? '65' : '40'}%">Question</th>
                  <th width="${isHideComments ? '35' : '20'}%">Answer</th>`
        if (!isHideComments) htmlData += `<th width="40%">Comments</th>`
        htmlData += `</tr>
              </thead>
              <tbody>
                <tr>
                  <td>${fields[i].label}</td>
                  <td>${getText}</td>`
        if (!isHideComments) htmlData += `<td>${model[fields[i].name] ? model[fields[i].name]['comments'] : ''}</td>`
        htmlData += `</tr>
              </tbody>
            </table>
          </div>`
        break
      }
      case 11: { // DIVIDER
        htmlData += `<div class="col-xs-12"><div style="background: #C0C0C0;padding:.35rem .25rem;border-top:1px solid;border-bottom:1px solid;"><strong>${fields[i].label}</strong></div></div>`
        break
      }
      case 12: { // GRID
        // htmlData += `<div class="col-xs-12"><label>${fields[i].label}</label>
        htmlData += `<div class="col-xs-12">
          ${getGridReportData(model[fields[i].name], fields[i].default_value.grid_data.labels)}
        </div>`
        break
      }
      case 13: { // SIGNATURE
        htmlData += `<div class="col-xs-6 mb-2 pb-2"><label>${fields[i].label}</label> <img src="${model[fields[i].name]}" class="img-responsive signature_13"/> </div>`
        break
      }
      case 15: { // INVENT COMP
        let headers = model[fields[i].name] || {}
        let htmlthead = '<tr style="font-size: 11px!important">'
        let htmlcontent = ''
        if (!headers.grid_header) headers.grid_header = { labels: [] }
        for (let j = 0; j < headers.grid_header.labels.length; j++) {
          if (j === 0) htmlthead = htmlthead + `<th width="20%" style="vertical-align: bottom;border: 1px solid white;border-bottom: 1px solid #ddd;">${headers.grid_header.labels[j].label}</th>`
          else htmlthead = htmlthead + `<th colspan="2" style="vertical-align: bottom;border: 1px solid white;border-bottom: 1px solid #ddd;">${headers.grid_header.labels[j].label}</th>`
        }
        htmlthead = `${htmlthead}</tr>`
        if (!headers.grid_data) headers.grid_data = { values: [] }
        for (let gridData of headers.grid_data.values) {
          htmlcontent = htmlcontent + `<tr style="font-size: 11px!important">`
          for (let l = 0; l < headers.grid_header.labels.length; l++) {
            if (l === 0) htmlcontent = `${htmlcontent} <td  width="20%">${gridData.name ? gridData.name : ''}</td>`
            else {
              let compValue = `compValue_${l}`
              htmlcontent = htmlcontent +
              `<td width="16%">
                ${gridData[compValue] ? gridData[compValue] : ''}
              </td>
              <td width="4%">
                ${gridData[compValue] ? gridData[compValue] : ''}
              </td>`
            }
          }
          htmlcontent = htmlcontent + `</tr>`
        }
        htmlData += `<div class="col-xs-12" style="padding: 0;">
        <table class="table table-bordered proptable" style="width:100%;">
          <tbody>
            ${htmlthead}
            ${htmlcontent}
          </tbody>
        </table>
        </div>`
        break
      }
      case 16: {
        let defaultValue = fields[i].default_value
        defaultValue.html_content = defaultValue.html_content || ''
        htmlData += `<div class="class="col-xs-6" style="padding: 0.25rem; background-color: #f7f7f7;margin-bottom:5px">${defaultValue.html_content}</div>`
        break
      }
      case 18: {
        // fields[i] is element obj
        // model is value obj
        let defaultValue = fields[i].default_value || {}
        if (defaultValue.grid_data && defaultValue.grid_data.labels && defaultValue.grid_data.labels.length) {
          htmlData += `
          <div class="col-xs-12">
            ${dataGridReportDataConstruct(defaultValue.grid_data.labels, model[fields[i].name], fields[i].showSum)}
          </div>`
        }
        break
      }
      case 19: { // ADS COMPONENT
        let values = model[fields[i].name] || {}
        let options = fields[i].default_value.options.filter(x => x.isactive) || []
        let selectType = 0
        if (fields[i].default_value.surveyOptions) {
          let { selectionType } = fields[i].default_value.surveyOptions
          selectType = selectionType || 0// 0 is radio and 1 is checkbox
        } let getText = ''
        if (selectType === 1) {
          let getItems = options.filter(x => values.answer.includes(x.value))
          if (getItems.length) getText = getItems.map(x => x.label).join(', ')
        } else if (selectType === 0) {
          let getItem = options.find(x => x.value === values.answer || '')
          getText = getItem ? getItem.label : ''
        }
        let checkboxFlags = fields[i].default_value.checkboxFlags
        let signAndDate = ''
        if (checkboxFlags.signature) signAndDate += values.signature || ''
        if (checkboxFlags.date) signAndDate += (checkboxFlags.signature ? ' - ' : '') + values.date || ''
        htmlData += `<div class="col-xs-12">
        <table class="table table-bordered table-padding-adjusted" style="width: 100%">
                  <thead>
                      <tr>
                        <th width="45%">Emne</th>
                        <th width="17%">Valg</th>
                        <th width="38%">Kommentar</th>
                      </tr>
                  </thead><tbody>`
        htmlData += `<tr>
                  <td>${fields[i].label}</td>
                  <td>${getText}</td>
                  <td>
                      <div style="width:100%">
                          <h5 style="margin:0 !important;"><span style="width:49.5%;float:left;display:none;"><strong>Comment</strong></span> <span style="width:49.5%;float:right;" class="badge badge-info sign-date">${signAndDate}</span><p class="custom-margin">${model[fields[i].name].comments || ''}</p></h5>
                      </div>
                  </td>
                  </tr>`
        htmlData += `</tbody></table></div>`
        break
      }
      default:
        htmlData += `<div class="col-xs-6"><label>${fields[i].label}</label> <span style="white-space: pre-wrap" class="textWrap"> ${(model && model[fields[i].name]) ? model[fields[i].name] : ''}</span></div>`
        break
    }
    if (evenCount === 2) {
      htmlData += `</div>`
      evenCount = 0
    }
  }
  if (fields.length % 2 !== 0) htmlData += `</div>`
  return htmlData
}

/* Grid component addition */
const getGridReportData = (gridList, tableHeader) => {
  let headers = gridList
  let gridHeadReport = '<div><table class="table table-bordered"><tr>'
  for (let x in tableHeader) {
    gridHeadReport += `<th style="height: 30px">${tableHeader[x].label}</th>`
  }
  gridHeadReport += `</tr>`
  for (let i in headers) {
    gridHeadReport += `<tr>`
    for (let j in headers[i]) {
      gridHeadReport += `<td style="height: 30px">${headers[i][j].value}</td>`
    }
    gridHeadReport += `</tr>`
  }
  gridHeadReport += `</table></div>`
  return `${gridHeadReport}`
}
/* --- */

/* DataGrid component addition */
const dataGridReportDataConstruct = (lables, dataRows, showSum) => {
  if (!dataRows) dataRows = []
  let table = '<table class="table table-bordered"><thead><tr>'
  /* For headers */
  lables.forEach(labelObj => {
    table += `<th width="${+labelObj.width}%">${labelObj.label}</th>`
  })
  table += '</tr></thead>'
  table += '<tbody>'
  Object.values(dataRows).forEach((rowData, rowIndex) => {
    table += '<tr>'
    lables.forEach((tdObj, tdIndex) => {
      switch (tdObj.field.type) {
        /* case 'string':
        case 'number':
        case 'date':
        case 'label':
          break */
        case 'checkbox':
          let checkboxValues = rowData[tdIndex].value
          let cvalues = tdObj.field.typedata.listItems || []
          table += `<td><div class="col-xs-12">`/* <label>${tdObj.label}</label> */
          for (let cvalue of cvalues) {
            table += ` <div class="checkbox-inline custom-margin-left"><label>`
            if (checkboxValues && checkboxValues.find(x => x === cvalue.value)) table += `<input type="checkbox" checked> ${cvalue.label}`
            else table += `<input type="checkbox"> ${cvalue.label}`
            table += `</label></div>`
          }
          table += `</div></td>`
          break
        case 'radiobox':
          let rvalues = tdObj.field.typedata.listItems || []
          table += `<td><div class="col-xs-12">`/* <label>${fields[i].label}</label> */
          for (let item of rvalues) {
            table += ` <label class="radio-inline custom-margin-left">`
            if (item.value === rowData[tdIndex].value) table += `<input type="radio" name="${rowIndex}_${item.name}" checked> ${item.label}`
            else table += `<input type="radio" name="${item.name}"> ${item.label}`
            table += `</label>`
          }
          table += `</div></td>`
          break
        case 'select':
          let options = tdObj.field.typedata.listItems || []
          let isMultiSelect = tdObj.field.typedata.isMultiSelect || false
          let select = rowData[tdIndex].value || []
          table += `<td><div class="col-xs-12">`/* <label>${fields[i].label}</label> */
          if (isMultiSelect) {
            let multiItems = []
            for (let selectItem of select) {
              let getItem = options.find(x => x.value === selectItem)
              let getText = getItem ? getItem.label : ''
              multiItems.push(getText)
            }
            table += `<span style="white-space: pre-wrap"> ${multiItems.join(', ')}</span>`
          } else {
            if (options) {
              let getItem = options.find(x => x.value === select)
              let getText = getItem ? getItem.label : ''
              table += `<span style="white-space: pre-wrap"> ${getText || ''}</span>`
            }
          }
          table += `</div></td>`
          break
        case 'label':
          table += `<td>`
          if (tdObj.field.typedata.calculatingValue && tdObj.field.typedata.insertPosition === 'prefix') table += `<span>${tdObj.field.typedata.calculatingValue * (rowIndex + 1)} </span>`
          table += `${tdObj.field.typedata.labeltext || ''}`
          if (tdObj.field.typedata.calculatingValue && tdObj.field.typedata.insertPosition === 'suffix') table += ` <span>${tdObj.field.typedata.calculatingValue * (rowIndex + 1)}</span>`
          table += `</td>`
          break
        default:
          table += `<td>${rowData[tdIndex].value}</td>`
          break
      }
    })
    table += '</tr>'
  })
  if (showSum && showSum.showSumRow && !isEmptyObject(dataRows)) {
    table += '<tr>'
    lables.forEach((sumColumn, sumIndex) => {
      table += '<th>'
      if (showSum.sumColIndexes.includes(sumIndex)) table += `${getGridDataValuesTotal(sumIndex, dataRows)}`
      table += `</th>`
    })
    table += '</tr>'
  }
  table += '</tbody></table>'
  return table
}

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
const getGridDataValuesTotal = (sumIndex, data) => {
  let total = 0
  let rows = Object.keys(data)
  let allRowValues = []
  rows.forEach(key => {
    var obj = data[key].find((x, y) => y === sumIndex)
    if (obj) allRowValues.push(parseFloat(obj.value || 0))
  })
  total = getSum(allRowValues)
  return total
}
const getSum = (arr) => {
  return [...arr].reduce((acc, val) => acc + val, 0)
}
/* --- */

export { generate_pdf_content }
